<template>
  <div
    component-name="txt-indoor-outdoor"
    v-if="indoor || outdoor"
    class="inline-flex text-overline-small/none whitespace-nowrap text-skin-muted select-none"
  >
    <span v-if="indoor && outdoor" v-html="$t.labels.indoor_outdoor"></span>
    <span v-else-if="indoor" v-html="$t.labels.indoor"></span>
    <span v-else-if="outdoor" v-html="$t.labels.outdoor"></span>
  </div>
</template>

<script setup>
const props = defineProps({
  indoor: {
    type: Boolean,
    required: false,
    default: false,
  },
  outdoor: {
    type: Boolean,
    required: false,
    default: false,
  },
});
</script>
